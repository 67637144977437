import Sidebar from '../SidebarNavigation'
import Template from '../Template'
import * as style from './style.module.scss'
import Link from 'gatsby-link'
import React, { useEffect } from 'react'

const PageMessages = () => {
  // useEffect(async () => {
  //   const { StreamChat } = await import('stream-chat')
  //   const client = StreamChat.getInstance('dz5f4d5kzrue')
  //   await client.connectUser(
  //     {
  //       id: 'quiet-cherry-8',
  //       name: 'quiet',
  //       image: 'https://bit.ly/2u9Vc0r',
  //     },
  //     'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiamFjcXVlbGluZS1kb2dnb3MtY29tIiwiZW1haWwiOiJqYWNxdWVsaW5lQGRvZ2dvcy5jb20iLCJpYXQiOjE3MTk5NDk1MzUsImV4cCI6MTcyNDc4NzkzNX0.2DCTfkhRHjpj6HvgUC5B6OFYpHTYWq80mLECtsQ9EKk'
  //   ) // token generated server side
  // }, [])

  return (
    <Template fullWidth>
      <div className={style.pageWrapper}>
        <Sidebar />
        <div className={style.content}>
          <h1 className={style.pageTitle}>Messages</h1>
          <div className={style.tabMenu}>
            <a href='javascript:void(0);' className={style.tab}>
              Current
            </a>
            <a href='javascript:void(0);' className={style.tab}>
              Past
            </a>
            <a href='javascript:void(0);' className={style.tab}>
              Archived
            </a>
          </div>
          <Link to={'/message'} className={style.cardMessage}>
            <div className={`${style.cardStatus} ${style.confirmed}`}>
              Confirmed
            </div>
            <div className={style.cardTitle}>Vacation at the dog castle</div>
            <div className={style.cardDate}>Nov 4 - 9, 2023</div>
            <div className={style.tagList}>
              <div className={style.tag}>Bossy</div>
              <div className={style.tag}>Aurelieus</div>
            </div>
          </Link>
          <Link to={'/message'} className={style.cardMessage}>
            <div className={`${style.cardStatus} ${style.pending}`}>
              Pending
            </div>
            <div className={style.cardTitle}>Ari's House</div>
            <div className={style.cardDate}>Nov 4 - 9, 2023</div>
            <div className={style.tagList}>
              <div className={style.tag}>Bossy</div>
              <div className={style.tag}>Aurelieus</div>
            </div>
          </Link>
          <Link to={'/message'} className={style.cardMessage}>
            <div className={`${style.cardStatus} ${style.pending}`}>
              Pending
            </div>
            <div className={style.cardTitle}>Barkingham Palace</div>
            <div className={style.cardDate}>Nov 4 - 9, 2023</div>
            <div className={style.tagList}>
              <div className={style.tag}>Bossy</div>
              <div className={style.tag}>Aurelieus</div>
            </div>
          </Link>

          <Link to={'/message'} className={style.cardMessage}>
            <div className={`${style.cardStatus} ${style.pending}`}>
              Pending
            </div>
            <div className={style.cardTitle}>
              Your Dog's Favorite Home Away from Home
            </div>
            <div className={style.cardDate}>Nov 4 - 9, 2023</div>
            <div className={style.tagList}>
              <div className={style.tag}>Bossy</div>
              <div className={style.tag}>Aurelieus</div>
            </div>
          </Link>
        </div>
      </div>
    </Template>
  )
}

export default PageMessages
